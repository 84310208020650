.pictures {
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
}
.pictures::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
}
.scroll_container{
    animation: slide 30s linear infinite;
    position: absolute;
    /* &:hover {
        animation-play-state: paused;
    } */
}
@keyframes slide {
    0% {
      left: 0;
    }
    100% {
      left: -100%;
    }
  }